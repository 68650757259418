// DisplayComponent.js
import React, { useState, useEffect } from 'react';
import './App.css';
// import FormComponent from './FormComponent';
// import axios from 'axios'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs,deleteDoc,doc } from 'firebase/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDZ0Ro9pB7mr6SCDTZdh-1WZsEgPhJIGw",
  authDomain: "jlr-develope.firebaseapp.com",
  databaseURL: "https://jlr-develope-default-rtdb.firebaseio.com",
  projectId: "jlr-develope",
  storageBucket: "jlr-develope.appspot.com",
  messagingSenderId: "24037086769",
  appId: "1:24037086769:web:b3d20892490183eef7e710",
  measurementId: "G-Q140C8JN0S"
};
const DisplayComponent = () => {
  const [formDataList, setFormDataList] = useState([]);
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)


const fetchData = async () => {
  try {
    const formsCollection = await getDocs(collection(db, 'forms'));
    const formsData = formsCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setFormDataList(formsData);
  } catch (error) {
    console.error('Error fetching form data:', error);
  }
};

//  const clearAllData = async () => {
//     try {
//       const formsCollection = await getDocs(collection(db, 'forms'));
//       const deletePromises = formsCollection.docs.map((doc) => deleteDoc(doc.ref));
//       await Promise.all(deletePromises);
//       fetchData(); // Fetch the data again after clearing
//     } catch (error) {
//       console.error('Error clearing form data:', error);
//     }
//   };
  useEffect(() => {
    fetchData();
  }, []);

 

  return (
    <div>
      <h1>קריאות </h1>
      <button  >Clear All Data</button>
      <table>
        <thead>
          <tr>
            <th>שם מלא</th>
            <th>אימייל</th>
         <th>טלפון</th>
          <th>מחלקה</th>
          <th>תת-מחלקה</th>
          <th>סוג הבעיה</th>
          <th>עדיפות</th>
          <th>תיאור הבעיה</th>
          <th>הערות</th>
          <th>סטטוס</th>
          <th>זמן טיפול לחיוב</th>
          </tr>
        </thead>
        <tbody>
          {formDataList.map((formDataItem, index) => (
            <tr key={index}>
              <td>{formDataItem.name}</td>
              <td>{formDataItem.email}</td>
              <td>{formDataItem.phone}</td>
              <td>{formDataItem.department}</td>
              <td>{formDataItem.tat_department}</td>
              <td>{formDataItem.type_of_problem}</td>
              <td>{formDataItem.priority}</td>
              <td>{formDataItem.description}</td>
              <td><input type='text'></input></td>
              <td><input type='checkbox' ></input></td>
              <td><input type='number'></input></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayComponent;





