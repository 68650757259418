// FormComponent.js
import './App.css';
import React, {  useState,useRef } from 'react';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, } from 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDZ0Ro9pB7mr6SCDTZdh-1WZsEgPhJIGw",
  authDomain: "jlr-develope.firebaseapp.com",
  databaseURL: "https://jlr-develope-default-rtdb.firebaseio.com",
  projectId: "jlr-develope",
  storageBucket: "jlr-develope.appspot.com",
  messagingSenderId: "24037086769",
  appId: "1:24037086769:web:b3d20892490183eef7e710",
  measurementId: "G-Q140C8JN0S"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const FormComponent = () => {
  const formRef = useRef(null)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone:'',
   description:'',
   department: '',
  tat_department:'',
  type_of_problem:'',
 priority:'',
  fileupload:'',


  });


  
    
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send data to your server
      const response = await fetch('http://localhost:3001/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // If successful, reset the form and show success alert
        const docRef = await addDoc(collection(db, 'forms'), formData);
        console.log('Document written with ID: ', docRef.id);
        alert('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          description: '',
          fileupload: '',
          department: '',
          tat_department: '',
          type_of_problem: '',
          priority: '',
        });
        formRef.current.reset();
      } else {
        throw new Error('Failed to submit form to the server');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please check the console for more details.');
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const docRef = await addDoc(collection(db, 'forms'), formData);
  //     console.log('Document written with ID: ', docRef.id);
  //     alert('Form submitted successfully');
  //     setFormData({
  //       name: '',
  //       email: '',
  //       phone: '',
  //       description: '',
  //       fileupload: '',
  //       department: '',
  //       tat_department: '',
  //       type_of_problem: '',
  //       priority: '',
  //     });
  //     formRef.current.reset();
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     alert('Error submitting form. Please check the console for more details.');
  //   }
  // };
  // console.log(formData)



  
  const handlefileupload =() =>{
 alert("הקובץ עלה בהצלחה")


 }


  return (
    <div id="myElement">
 <h1>מערכות מידע</h1>
 <h3>פתיחת תקלה</h3>


    <form onSubmit={handleSubmit} ref={formRef} id="MyForm" >
      <label htmlFor='name'>שם מלא</label> <br>
 </br>
      <input
        type="text"
        name="name"
        id="full_name"
        value={formData.name}
        onChange={handleInputChange}
        onBlur={handleInputChange}
        placeholder="Name"
        autoComplete='off'
      />
       <label htmlFor='name'>אימייל</label> <br></br>
      <input
        type="email"
        name="email"
        autoComplete='off'
        value={formData.email}
        onChange={handleInputChange}
        onBlur={handleInputChange}
        id="email"
        placeholder="Email"
      />

       


 <label htmlFor='phonenumber'>טלפון</label> <br>
 </br>
 <input type="text"
         onChange={handleInputChange}
         onBlur={handleInputChange}
        name="phone"
        id="phonenumber"
      value={formData.phone}
      placeholder="Phone"
      autoComplete='off'

       ></input>
       <br>
       </br>
       
 <label>מחלקה</label>
 <select onChange={handleSelectChange} value={formData.department} id='department' name='department'>
<option ></option>
<option >כספים</option >
<option >הנהלה</option>
<option>שירות לקוחות</option>
<option>מוסכים</option>
<option>חלפים</option>
<option>אחזקות</option>
<option>Yts</option>
<option>אחר</option>

</select >






<label>תת מחלקה</label>
<select  id='tat_department' onChange={handleSelectChange} value={formData.tat_department} name='tat_department'>
    <option></option>
<option>מוסך כ"ס</option>
<option>מוסך חיפה</option>
<option>מוסך תל אביב</option>
<option>גרינפלד</option>
<option>מרכבה</option>
<option>אולם הרצליה</option>
<option>אולם ב"ש</option>
<option>אולם חיפה</option>
<option>אולם כ"ס</option>
<option>Pdi</option>

</select>
<label>סוג הבעיה</label>
<select  id='type_of_issue' onChange={handleSelectChange} value={formData.type_of_problem} name='type_of_problem'>
<option ></option>
<option>חומרה-ציוד מחשוב</option>
<option>תוכנה-פריוריטי</option>
<option>תוכנה-AS400</option>
<option>תוכנה-אתר יצרן </option>
<option>בקשת הרשאה </option>
<option>שליפת נתונים </option>
<option>אחר</option>


</select  >
<label>עדיפות</label >
<select  id='priority' onChange={handleSelectChange} value={formData.priority} name='priority'>
<option ></option>
<option>רגילה</option>
<option>בינוני</option>
<option>דחוף</option>
</select>

<label for='fname'>תיאור בעיה</label>
<br></br>
<input type="text"
        id="message"
        name="description"
      onChange={handleInputChange}
      onBlur={handleInputChange} 
      autoComplete='off'
       ></input><br></br>
       <br></br>

<button  type='submit'   className="submitbutton"  >אישור </button> <br></br><br></br>
<label for="file-upload" class="custom-file-upload">
    <i class="fa fa-cloud-upload"></i> העלאת קובץ
</label>
<input id="file-upload" type="file" onChange={handlefileupload} name='fileupload' value={formData.fileupload} autoComplete='off'/>
    </form>
    </div>
  );
};

export default FormComponent;
